import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateNewDiscountPromoCode = () => {
    const [description, setDescription] = useState('');
    const [offerDiscount, setOfferDiscount] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccessMessage, setSubmitSuccessMessage] = useState(null);
    const navigate = useNavigate(); // Initialize navigate
  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      // Create FormData object
      const formData = new FormData();
      formData.append('description', description);
      formData.append('offerDiscount', offerDiscount);
      formData.append('couponCode', couponCode);
  
      const token = localStorage.getItem('token'); // Ensure token is stored in localStorage
  
      if (!token) {
        setSubmitError('Token not found. Please log in again.');
        return;
      }
  
      try {
        const response = await fetch('https://app.lvintage.com/admin/add/offer', {
          method: 'POST',
          headers: {
            'Authorization': token, // Ensure Bearer is prefixed to the token
          },
          body: formData, // Send FormData as body
        });
  
        if (response.ok) {
          setSubmitSuccessMessage('Offer added successfully!');
          setDescription('');
          setOfferDiscount('');
          setCouponCode('');
        } else {
          const errorData = await response.json();
          console.error('Error details:', errorData);
          setSubmitError(errorData.message || 'An error occurred while submitting the form.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setSubmitError('An unexpected error occurred.');
      }
    };
    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
    const generateDiscountOptions = () => {
      const options = [];
      for (let i = 5; i <= 100; i += 5) {
        options.push(<option key={i} value={i}>{i}%</option>);
      }
      return options;
    };
  
    return (
      <div className="container bag-form">
      <i className="fa fa-arrow-left" onClick={handleBack}></i>
        <div className="row">
          <div className="col-md"></div>
          <div className="col-md-10">
            <form onSubmit={handleFormSubmit}>
              <div className="input-container">
                <label>Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Enter offer description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Offer Discount (%)</label>
                <select
                  id="offerDiscount"
                  name="offerDiscount"
                  value={offerDiscount}
                  onChange={(e) => setOfferDiscount(e.target.value)}
                  required
                >
                  <option value="">Select Discount</option>
                  {generateDiscountOptions()}
                </select>
              </div>
  
              <div className="input-container">
                <label>Coupon Code</label>
                <input
                  type="text"
                  id="couponCode"
                  name="couponCode"
                  placeholder="Enter coupon code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  required
                />
              </div>
  
              <button type="submit" className="submit-button">Submit Offer</button>
  
              {submitError && <p className="error-message">{submitError}</p>}
              {submitSuccessMessage && <p className="success-message">{submitSuccessMessage}</p>}
            </form>
          </div>
          <div className="col-md"></div>
        </div>
      </div>
    );
};
export default CreateNewDiscountPromoCode;