import React, { useState  } from 'react';
import '../../Pages/createNewArtistMonth/CreateNewArtistMonth.css';
import { useNavigate } from 'react-router-dom';


export const CreateNewArtistMonth = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccessMessage, setSubmitSuccessMessage] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      // Create FormData object
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('description', description);
      formData.append('image', image);
  
      const logFormData = (formData) => {
        const data = {};
        formData.forEach((value, key) => {
          data[key] = value;
        });
        console.log(data);
      };
  
      logFormData(formData);
  
      const token = localStorage.getItem('token'); // Make sure the token is saved in localStorage
  
      if (!token) {
        setSubmitError('Token not found. Please log in again.');
        return;
      }
  
      try {
        const response = await fetch('https://app.lvintage.com/admin/add/artist-of-month', {
          method: 'POST',
          headers: {
            'Authorization': token,
          },
          body: formData,
        });
  
        if (response.ok) {
          setSubmitSuccessMessage('Artist added successfully!');
          setFirstName('');
          setLastName('');
          setDescription('');
          setImage(null);
        } else {
          const errorData = await response.json();
          console.error('Error details:', errorData);
          setSubmitError(errorData.message || 'An error occurred while submitting the form.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setSubmitError('An unexpected error occurred.');
      }
    };
  
    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
    };
  
    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
  
    return (
      <div className="container artist-form">
      <i class="fa fa-arrow-left" onClick={handleBack} ></i>
        <div className="row">
        <div className="col-md"></div>
          <div className="col-md-9">
            <form onSubmit={handleFormSubmit}>
              <div className="input-container">
                <label>First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Description</label>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Upload Image</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  onChange={handleImageChange}
                  required
                />
              </div>
  
              <button type="submit" className="submit-button">Submit Artist</button>
  
              {submitError && <p className="error-message">{submitError}</p>}
              {submitSuccessMessage && <p className="success-message">{submitSuccessMessage}</p>}
            </form>
          </div>
        <div className="col-md"></div>
        </div>
      </div>
    );
}
export default  CreateNewArtistMonth