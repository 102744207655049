import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import '../../Pages/dashboard/Dashboard.css';
import Inventrynew from '../../Pages/inventoryNewBags/InventoryNewBags.js';
import Inventrysecond from '../../Pages/inventorySecondLife/InventorySecondLife.js';
import Ordernew from '../../Pages/orderNewBags/OrderNewBags.js';

import totalorder from '../../Images/Total_Order.png';
import stockavlb from '../../Images/Stock Available.png';
import totaluser from '../../Images/Total users.png';
import repaireq from '../../Images/Total_Repair_Request.png';

function Dashboard() {
  const [currentDate, setCurrentDate] = useState("");
  const [greeting, setGreeting] = useState("Good Morning");
  const [dashboardData, setDashboardData] = useState({
    totalOrders: 0,
    totalRepairs: 0,
    availableStock: 0,
    totalUsers: 0,
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const updateDateTime = () => {
      const today = new Date();
      const hours = today.getHours();
      let greetingMessage;

      if (hours < 12) {
        greetingMessage = "Good Morning";
      } else if (hours < 18) {
        greetingMessage = "Good Afternoon";
      } else {
        greetingMessage = "Good Evening";
      }

      setGreeting(greetingMessage);

      const formattedDate = today.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const formattedTime = today.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      setCurrentDate(`${formattedDate} ${formattedTime}`);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Token ko local storage se get karna

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    fetch('https://app.lvintage.com/admin/dashBoard', {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unauthorized');
        }
        return response.json();
      })
      .then((data) => {
        if (data.code === 200) {
          setDashboardData(data.result);
        }
      })
      .catch((error) => {
        console.error('Error fetching dashboard data:', error);
        setError(error.message);
      });
  }, []);

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="dashboard">
      <div className="header">
        <div className="greeting">
          <h2>{greeting}, Lvintage!</h2>
          <p>Here's what's happening with your store today.</p>
        </div>
        <div className="date-time">
          <p>{currentDate}</p>
        </div>
      </div>
      <div className="stats">
        <div className="card earnings">
          <div className="card-content">
            <p className="card-title">Total <br />Orders</p>
            <h3>{dashboardData.totalOrders}</h3>
          </div>
          <div className="card-icon">
            <img src={totalorder} alt="" style={{ backgroundColor: '#34988552' }} />
          </div>
        </div>
        <div className="card orders">
          <div className="card-content">
            <p className="card-title"> Stock <br />Availability</p>
            <h3>{dashboardData.availableStock}</h3>
          </div>
          <div className="card-icon">
            <img src={stockavlb} alt="" style={{ backgroundColor: '#4698ce4f' }} />
          </div>
        </div>
        <div className="card customers">
          <div className="card-content">
            <p className="card-title">Total<br /> Users</p>
            <h3>{dashboardData.totalUsers}</h3>
          </div>
          <div className="card-icon">
            <img src={totaluser} alt="" style={{ backgroundColor: '#f7b84c4a' }} />
          </div>
        </div>
        <div className="card balance">
          <div className="card-content">
            <p className="card-title"> Repair<br /> Requests</p>
            <h3>{dashboardData.totalRepairs}</h3>
          </div>
          <div className="card-icon">
            <img src={repaireq} alt="" style={{ backgroundColor: '#6143714f' }} />
          </div>
        </div>
      </div>
      <div className="conatiner invnew">
        <div className="row">
          <div className="col-md">
            <div className="inflx">
              <h2>Inventory NewBags Details</h2>
              <Link to="/inventoryNewBags">View all <i className="fa fa-arrow-right"></i></Link>
            </div>
            <Inventrynew />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <div className="inflx">
              <h2>Inventory Second Life Details</h2>
              <Link to="/inventorySecondLife">View all <i className="fa fa-arrow-right"></i></Link>
            </div>
            <Inventrysecond />
          </div>
        </div>
      </div>
      <div className="conatiner invnew">
        <div className="row">
          <div className="col-md">
            <div className="inflx">
              <h2>Order Details</h2>
              <Link to="/orderManagement">View all <i className="fa fa-arrow-right"></i></Link>
            </div>
            <Ordernew />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
