// ChangePassword.js
import React, { useState } from 'react';
import '../../Pages/changeUserPwd/ChangeUserPwd.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const ChangePassword = () => {
  const [emailPwdChange, setEmailPwdChange] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate(); // Initialize navigate


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', emailPwdChange);
    formData.append('password', password);

    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    try {
      const response = await fetch(`https://app.lvintage.com/admin/reset/user/password`, {
        method: 'POST',
        headers: {
          'Authorization': token,
        },
        body: formData,
      });

      if (response.ok) {
        setSuccessMessage('Password changed successfully!');
        setEmailPwdChange('');
        setPassword('');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'An error occurred while changing the password.');
      }
    } catch (error) {
      setError('An unexpected error occurred.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div className="container usrpwdch usrpwdchs" style={{height: '96vh'}}>
       <i class="fa fa-arrow-left" onClick={handleBack}></i>
      <div className='row'>
      <div className='col-md'></div>
        <div className='col-md-6' style={{ marginTop: 68 }}>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>Email</label>
              <input
                type="email"
                id="emailPwdChange"
                name="emailPwdChange"
                placeholder="Your Email ID"
                value={emailPwdChange}
                onChange={(e) => setEmailPwdChange(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>New Password</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Your New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: 'pointer' }}
                >
                  {showPassword ? "🙈" : "👁️"}
                </i>
              </div>
            </div>

            <button type="submit" className="changepwd-button">Change Password</button>

            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
          </form>
        </div>
      <div className='col-md'></div>
      </div>
    </div>
  );
};

export default ChangePassword;
