import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const SendPromo = () => {
    const location = useLocation();
    const { id: offerId } = useParams(); // Extracting offerId from URL
    const { title, message } = location.state || {};
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Token not found. Please log in again.');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
            },
        };

        fetch('https://app.lvintage.com/admin/users', requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                if (result && result.result && Array.isArray(result.result)) {
                    const formattedData = result.result.map((item) => ({
                        sNo: item.id,
                        name: `${item.firstName} ${item.lastName}`,
                        email: item.email,
                    }));
                    setData(formattedData);
                    setFilteredData(formattedData);
                } else {
                    throw new Error('Unexpected response format');
                }
            })
            .catch((error) => {
                setError(error.message);
            });
    }, []);

    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = data.filter(item =>
            item.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            item.email.toLowerCase().includes(lowerCaseSearchTerm)
        );
        setFilteredData(filtered);
    }, [searchTerm, data]);

    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter((itemId) => itemId !== id)
                : [...prevSelectedIds, id]
        );
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds(filteredData.map((item) => item.sNo));
        } else {
            setSelectedIds([]);
        }
    };

    const handleSubmit = async () => {
        if (selectedIds.length === 0) {
            alert('Please select at least one user ID before sending the notification.');
            return;
        }

        const formData = new FormData();
        formData.append('offerId', offerId); // Use the extracted offerId
        formData.append('userIds', selectedIds.join(','));

        // Log the formData contents
        console.log('Form Data being sent:');
        formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });

        try {
            const response = await fetch('https://app.lvintage.com/admin/send/offers', {
                method: 'POST',
                headers: {
                    'Authorization': `${localStorage.getItem('token')}`,
                },
                body: formData,
            });

            if (response.ok) {
                alert('Notification sent successfully!');
                navigate(-1);
            } else {
                const errorData = await response.json();
                console.error('Error details:', errorData);
                alert(errorData.message || 'An error occurred while sending the notification.');
            }
        } catch (error) {
            console.error('Unexpected error:', error);
            alert('An unexpected error occurred.');
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectedIds.length === filteredData.length && filteredData.length > 0}
                    />
                ),
                id: 'selectAll',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        checked={selectedIds.includes(row.original.sNo)}
                        onChange={() => handleCheckboxChange(row.original.sNo)}
                    />
                ),
            },
            { Header: 'S.No', accessor: 'sNo' },
            { Header: 'Name', accessor: 'name' },
            { Header: 'Email', accessor: 'email' },
        ],
        [selectedIds, filteredData]
    );

    const tableInstance = useTable({ columns, data: filteredData });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className='sendnoti'>
            {error && <div className="error">{error}</div>}
            <h2>Send Offer</h2>
            <button onClick={handleSubmit} className='submit-button'>Send Offer</button>
            <input
                type="text"
                placeholder="Search by Name or Email"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <table {...getTableProps()} className="modern-table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SendPromo;
