import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Pages/artistOfTheMonth/ArtistOfTheMonth.css';
import '../../Pages/discountPromoCode/DiscountPromoCode.css';

export const DiscountPromoCode = () => {
  const [promos, setPromos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFullName, setShowFullName] = useState({});
  const [toggleStatus, setToggleStatus] = useState({});

  const navigate = useNavigate();

  const handleCreateNewDiscountClick = () => {
    navigate('/createNewDiscountPromoCode');
  };

  const fetchPromoData = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/offerAndDiscounts', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result)) {
          setPromos(result.result);
          const initialStatus = result.result.reduce((acc, promo) => {
            acc[promo.id] = promo.active === 1;
            return acc;
          }, {});
          setToggleStatus(initialStatus);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPromoData();
  }, []);

  const toggleName = (id) => {
    setShowFullName((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleToggleChange = (id) => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const updatedStatus = !toggleStatus[id];
    setToggleStatus((prevState) => ({
      ...prevState,
      [id]: updatedStatus,
    }));

    const formData = new FormData();
    formData.append('offerId', id);
    formData.append('isActive', updatedStatus ? 1 : 0);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        // FormData will set the appropriate Content-Type
      },
      body: formData,
    };

    fetch('https://app.lvintage.com/admin/enable/disable/offer', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('Toggle success:', result);
        fetchPromoData(); // Refresh the table after successful toggle
      })
      .catch((error) => {
        console.error('Toggle error:', error);
        setToggleStatus((prevState) => ({
          ...prevState,
          [id]: !updatedStatus,
        }));
        setError('Failed to update toggle status.');
      });
  };

  const handleSendClick = (id) => {
    // Navigating to the new page with the promo ID
    navigate(`/sendPromo/${id}`);
  };

  if (loading) {
    return <p>Loading promos...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div>
      <button onClick={handleCreateNewDiscountClick} className='newbtn'>Create New Discount Promo Code</button>

      <table className="modern-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Description</th>
            <th>Offer Percentage</th>
            <th>Coupon Code</th>
            <th>Status</th>
            <th>Offer</th> {/* Added Send button header */}
          </tr>
        </thead>
        <tbody>
          {promos.map((promo, index) => (
            <tr key={promo.id}>
              <td>{index + 1}</td>
              <td>{promo.id}</td>
              <td className="name">
                {showFullName[promo.id] ? (
                  <>
                    {promo.name}
                    <button onClick={() => toggleName(promo.id)}>Read Less..</button>
                  </>
                ) : (
                  <>
                    {promo.name.length > 10 ? (
                      <>
                        {promo.name.slice(0, 10)}...
                        <button onClick={() => toggleName(promo.id)}>Read More..</button>
                      </>
                    ) : (
                      promo.name
                    )}
                  </>
                )}
              </td>
              <td>{promo.offerPercentage}%</td>
              <td>{promo.couponCode}</td>
              <td>
                <div className="toggle-wrapper">
                  <input
                    className="toggle-checkbox"
                    type="checkbox"
                    checked={toggleStatus[promo.id] || false}
                    onChange={() => handleToggleChange(promo.id)}
                  />
                  <div className="toggle-container">
                    <div className="toggle-button toggle-buttons ">
                      <div className="toggle-button-circles-container">
                        {Array(12).fill().map((_, i) => (
                          <div key={i} className="toggle-button-circle"></div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <button onClick={() => handleSendClick(promo.id)}  className="view-detail-button">Send</button> {/* Added Send button */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DiscountPromoCode;
