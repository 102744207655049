import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateNewManualNotification = () => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  
  const navigate = useNavigate(); // Initialize navigate

  const handleCreateNotificationSubmit = (e) => {
    e.preventDefault();
    
    // Navigate to the ManualNotification page with title and message
    navigate('/manualNotification', { state: { title, message } });
  };

  return (
    <div className="container bag-form">
      <div className="row">
        <div className="col-md"></div>
        <div className="col-md-10">
          <form onSubmit={handleCreateNotificationSubmit}>
            <div className="input-container">
              <label>Title</label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <div className="input-container">
              <label>Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <button type="submit" className='submit-button'>Select Users</button>
          </form>
        </div>
        <div className="col-md"></div>
      </div>
    </div>
  );
};

export default CreateNewManualNotification;
