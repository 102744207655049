import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Pages/artistOfTheMonth/ArtistOfTheMonth.css';

export const BagOfTheMonth = () => {
  const [bags, setBags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Function to navigate to the "Create New Bag of the Month" page
  const handleCreateNewBagClick = () => {
    navigate('/createNewBagMonth'); 
  };

  // Function to navigate to the Bag Details page with the bag's ID
  const handleViewDetailsClick = (id) => {
    navigate(`/bagofTheMonthDetail/${id}`); 
  };

  // Fetch bags data
  const fetchBagData = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/get/all/bag-of-month', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result)) {
          setBags(result.result);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBagData();
  }, []);

  if (loading) {
    return <p>Loading bags...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div>
      <button onClick={handleCreateNewBagClick} className='newbtn'>Create New Bag of the Month</button>

      <table className="modern-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th> {/* New column for View Details button */}
          </tr>
        </thead>
        <tbody>
          {bags.map((bag, index) => (
            <tr key={bag.id}>
              <td>{index + 1}</td>
              <td>{bag.name}</td>
              <td>${bag.price}</td>
              <td>
                <button onClick={() => handleViewDetailsClick(bag.id)} className="view-btn view-detail-button">
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BagOfTheMonth;
