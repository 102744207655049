import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../../Pages/artistOfTheMonth/ArtistOfTheMonth.css';
import '../../Pages/artoftheMonthDetail/ArtoftheMonthDetail.css';

export const ArtoftheMonthDetail = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigate


  useEffect(() => {
    const fetchArtistDetail = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('Token not found. Please log in again.');
        setLoading(false);
        return;
      }

      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      };

      fetch('https://app.lvintage.com/admin/get/all/artist-of-month', requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          if (result && result.result) {
            // Find the artist by ID
            const foundArtist = result.result.find(artist => artist.id === parseInt(id)); // Compare with the parsed integer ID
            if (foundArtist) {
              setArtist(foundArtist);
            } else {
              setError('Artist not found.');
            }
          } else {
            console.error('Unexpected response format:', result);
            throw new Error('Unexpected response format');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('Fetch error:', error);
          setError(error.message);
          setLoading(false);
        });
    };

    fetchArtistDetail();
  }, [id]); // Depend on id

  if (loading) {
    return <p>Loading artist details...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div className='container newd'>
      <i className="fa fa-arrow-left" onClick={handleBack} style={{ "margin-top": "-42px", "margin-left": "-32px" }}></i>
      <div className='row'>
        <div className='col-md-6 scrol'>
        <h2 className='hding' style={{'margin-top': '0px'}}>Artist of the Month</h2>
          <p><strong>Name:</strong> {artist.firstName} {artist.lastName}</p>
          <p><strong>Description:</strong> {artist.description}</p>
        </div>
        <div className='col-md-6'>
          <div className='image-gallerys'>
            <img
              src={artist.imageUrl}
              alt={`${artist.firstName} ${artist.lastName}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtoftheMonthDetail;
