import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import '../manualNotification/ManualNotification.css';

export const ManualNotification = () => {
  const location = useLocation();
  const { title, message } = location.state || {}; // Get title and message from state
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // State for filtered data
  const [selectedIds, setSelectedIds] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/users', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result)) {
          const formattedData = result.result.map((item) => ({
            sNo: item.id,
            name: `${item.firstName} ${item.lastName}`,
            email: item.email,
          }));
          setData(formattedData);
          setFilteredData(formattedData); // Initialize filtered data
        } else {
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    // Filter data based on search term
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = data.filter(item =>
      item.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      item.email.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredData(filtered);
  }, [searchTerm, data]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((itemId) => itemId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(filteredData.map((item) => item.sNo));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSubmit = async () => {
    // Check if at least one ID is selected
    if (selectedIds.length === 0) {
      alert('Please select at least one user ID before sending the notification.');
      return; // Exit the function if no IDs are selected
    }

    // Log the data being sent
    console.log('Sending Notification:');
    console.log('Title:', title);
    console.log('Message:', message);
    console.log('Selected User IDs:', selectedIds);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    formData.append('userIds', selectedIds.join(',')); // Join selected IDs

    try {
      const response = await fetch('https://app.lvintage.com/admin/send/manual/notification', {
        method: 'POST',
        headers: {
          'Authorization': `${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        alert('Notification sent successfully!');
        navigate(-1); // Navigate back after sending
      } else {
        const errorData = await response.json();
        console.error('Error details:', errorData);
        alert(errorData.message || 'An error occurred while sending the notification.');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred.');
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            checked={selectedIds.length === filteredData.length && filteredData.length > 0}
          />
        ),
        id: 'selectAll',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedIds.includes(row.original.sNo)}
            onChange={() => handleCheckboxChange(row.original.sNo)}
          />
        ),
      },
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
    ],
    [selectedIds, filteredData]
  );

  const tableInstance = useTable({ columns, data: filteredData });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className='sendnoti'>
      {error && <div className="error">{error}</div>}
      <h2>Send Notification</h2>
      <p>Title: {title}</p>
      <p>Message: {message}</p>
      <button onClick={handleSubmit} className='submit-button'>Send Notification</button>
      <input
        type="text"
        placeholder="Search by Name or Email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManualNotification;
