import React, { useState } from 'react';
import '../../Pages/changeUserPwd/ChangeUserPwd.css';
import '../../Pages/createNewUser/CreateNewUser.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


export const CreateNewUser = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [createUserError, setCreateUserError] = useState(null);
    const [createUserSuccessMessage, setCreateUserSuccessMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

  
    const handleCreateUserSubmit = async (e) => {
      e.preventDefault();
      
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('userEmail', userEmail);
      formData.append('mobileNumber', mobileNumber);
      formData.append('userPassword', userPassword);
      
      const logFormData = (formData) => {
        const data = {};
        formData.forEach((value, key) => {
          data[key] = value;
        });
        console.log(data);
      };
      
      logFormData(formData);
      
      const token = localStorage.getItem('token');
      
      if (!token) {
        setCreateUserError('Token not found. Please log in again.');
        return;
      }
      
      try {
        const response = await fetch('https://app.lvintage.com/admin/create/user', {
          method: 'POST',
          headers: {
            'Authorization': token,
          },
          body: formData,
        });
        
        if (response.ok) {
          setCreateUserSuccessMessage('User created successfully!');
          setFirstName('');
          setLastName('');
          setUserEmail('');
          setMobileNumber('');
          setUserPassword('');
        } else {
          const errorData = await response.json();
          console.error('Error details:', errorData);
          setCreateUserError(errorData.message || 'An error occurred while creating the user.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setCreateUserError('An unexpected error occurred.');
      }
    };
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
    return (
      <div className="container usrpwdch">
       <i class="fa fa-arrow-left" onClick={handleBack}></i>
        <div className='row'>
         <div className='col-md'></div>
          <div className='col-md-8'>
            <form onSubmit={handleCreateUserSubmit}>
              <div className="input-container">
                <label>First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Email</label>
                <input
                  type="email"
                  id="userEmail"
                  name="userEmail"
                  placeholder="User Email ID"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Mobile Number</label>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                />
              </div>
  
              <div className="input-container">
                <label>Password</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="userPassword"
                    name="userPassword"
                    placeholder="User Password"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    required
                  />
                  <i
                    className="eye-icon"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? "🙈" : "👁️"}
                  </i>
                </div>
              </div>
  
              <button type="submit" className="changepwd-button">Create User</button>
  
              {createUserError && <p className="error-message">{createUserError}</p>}
              {createUserSuccessMessage && <p className="success-message">{createUserSuccessMessage}</p>}
            </form>
          </div>
         <div className='col-md'></div>

        </div>
      </div>
    );
  }; 
export default CreateNewUser;