import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom'; // Ensure you have this import for navigation
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';

export const RepairService = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`, 
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/repairRequests', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);

        if (result && Array.isArray(result.result)) {
          const formattedData = result.result.map((repair, index) => ({
            sNo: index + 1,
            id: repair.id,
            emailId: repair.emailId,
            mobileNumber: repair.mobileNumber,
            estimateCost: repair.estimatedCost,
            status: repair.status || 'N/A', // Assuming this might be a new field; adjust as needed
            repairDetail: "View Details",  // You can customize this
          }));

          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Id', accessor: 'id' },
      { Header: 'Mobile Number', accessor: 'mobileNumber' },
      { Header: 'Estimate Cost', accessor: 'estimateCost' },
      { Header: 'Email Id', accessor: 'emailId' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Repair Detail',
        accessor: 'repairDetail',
        Cell: ({ row }) => (
          <button
            className="view-detail-button"
            onClick={() => navigate(`/repairViewDetail/${row.original.id}`)} // Navigate with parameter if needed
          >
            {row.original.repairDetail}
          </button>
        ),
      },
    ],
    [navigate]
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      
      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RepairService;
