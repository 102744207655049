import React, { useState } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import '../../Pages/Sidebar/Sidebar.css';
import Dashboard from '../../Pages/dashboard/Dashboard.js';
import InventoryNewBags from '../inventoryNewBags/InventoryNewBags.js';
import InventorySecondLife from '../inventorySecondLife/InventorySecondLife.js';
import OrderNewBags from '../orderNewBags/OrderNewBags.js';
import OrderNewViewDetail from '../orderNewViewdetail/OrderNewViewDetail.js'; // Ensure this import
import SecoundLifeSell from '../secoundLifeSell/SecoundLifeSell.js';
import RepairService from '../repairService/RepairService.js';
import Support from '../support/Support.js';
import RepairViewDetail from '../repairViewDetail/RepairViewDetal.js';
import SecoundLifeSellView from '../secoundLifeSellView/SecoundLifeSellView.js';
import UserManagement from '../userManagement/UserManagement.js';
import ChangeUserPwd from '../changeUserPwd/ChangeUserPwd.js';
import CreateNewUser from '../createNewUser/CreateNewUser.js'
import ArtistOfTheMonth from '../artistOfTheMonth/ArtistOfTheMonth.js';
import BagOfTheMonth from '../bagOfTheMonth/BagOfTheMonth.js';
import DiscountPromoCode from '../discountPromoCode/DiscountPromoCode.js';
import CreateNewArtistMonth from '../createNewArtistMonth/CreateNewArtistMonth.js';
import CreateNewBagMonth from '../createNewBagMonth/CreateNewBagMonth.js';
import ManualNotification from '../manualNotification/ManualNotification.js';
import CreateNewDiscountPromoCode from '../createNewDiscountPromoCode/CreateNewDiscountPromoCode.js';
import CreateNewManualNotification from '../createNewManualNotification/CreateNewManualNotification.js';
import ArtoftheMonthDetail from '../artoftheMonthDetail/ArtoftheMonthDetail.js';
import BagofTheMonthDetail from '../bagofTheMonthDetail/BagofTheMonthDetail.js';
import SendPromo from '../sendPromo/SendPromo.js';


import Logos from '../../../Components/Images/VintageLogo.png';
import Group6802 from '../../../Components/Images/Group6802.png';
import Group6803 from '../../../Components/Images/Group6803.png';
import Group6804 from '../../../Components/Images/Group6804.png';
import Group6806 from '../../../Components/Images/Group6806.png';
import Group6807 from '../../../Components/Images/Group6807.png';
import homeicon from '../../../Components/Images/Group6808.png';
import Group6809 from '../../../Components/Images/Group6809.png';
import usermanimg from '../../Images/User Management.png';
import artmonth from '../../Images/artstofthemonth.png';
import bagmonth from '../../Images/Bagofthemonth.png';
import higlitmont from '../../Images/Highlightofthe month.png';
import promocode from '../../Images/promocode.png';
import manulnoti from '../../Images/Manual notification.png';


const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isInventorySubMenuOpen, setIsInventorySubMenuOpen] = useState(false);
    
    // Add this line to define the missing state
    const [isHighlightsMonthSubMenuOpen, setIsHighlightsMonthSubMenuOpen] = useState(false);

    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const toggleInventorySubMenu = () => {
        setIsInventorySubMenuOpen(!isInventorySubMenuOpen);
    };

    const toggleHighlightsMonthSubMenu = () => {
        setIsHighlightsMonthSubMenuOpen(!isHighlightsMonthSubMenuOpen); // No error now
    };

    const handleLogout = () => {
        navigate("/logout");
    };


    return (
        <div className="container-fluid sidbr">
            <div className="row">
                <div className="col-md-3 sidbrs">
                    <div className="sidebar">
                        <div className="logo">
                            <img src={Logos} alt="Logo" />
                        </div>
                        <nav className="menu">
                            <ul>
                                <li className={`menu-item ${isActive('/dashboard')}`}>
                                    <Link to="/dashboard">
                                        <img src={homeicon} alt="" />
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/')}`}>
                                    <Link to="#" onClick={toggleInventorySubMenu}>
                                        <img src={Group6809} alt="" />
                                        <span>Inventory Management <i className="fas fa-caret-down"></i></span>
                                    </Link>
                                </li>
                                <div className='mysubmneu'>
                                    {isInventorySubMenuOpen && (
                                        <ul className="submenu">
                                            <li className={`menu-item ${isActive('/inventoryNewBags')}`}>
                                                <Link to="/inventoryNewBags">
                                                    <img src={Group6807} alt="" />New Bags 
                                                </Link>
                                            </li>
                                            <li className={`menu-item ${isActive('/inventorySecondLife')}`}>
                                                <Link to="/inventorySecondLife">
                                                    <img src={Group6806} alt="" /> Second Life Bags
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <li className={`menu-item ${isActive('/orderManagement')}`}>
                                    <Link to="/orderManagement">
                                        <img src={usermanimg} alt="" />
                                        <span>Order Management</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/usermanagement')}`}>
                                    <Link to="/usermanagement">
                                        <img src={usermanimg} alt="" />
                                        <span>User Management</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/secoundLifeSell')}`}>
                                    <Link to="/secoundLifeSell">
                                        <img src={Group6806} alt="" />
                                        <span>Second life Sell</span>
                                    </Link>
                                </li>
                               
                                <li className={`menu-item ${isActive('/repairService')}`}>
                                    <Link to="/repairService">
                                        <img src={Group6804} alt="" />
                                        <span>Repair service</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/')}`}>
                                    <Link to="#" onClick={toggleHighlightsMonthSubMenu}>
                                        <img src={higlitmont} alt="" />
                                        <span>Highlights of the Month<i className="fas fa-caret-down"></i></span>
                                    </Link>
                                </li>
                                <div className='mysubmneu'>
                                    {isHighlightsMonthSubMenuOpen && (
                                        <ul className="submenu">
                                            <li className={`menu-item ${isActive('/artistOfTheMonth')}`}>
                                                <Link to="/artistOfTheMonth">
                                                    <img src={artmonth} alt="" />Artist of the Month 
                                                </Link>
                                            </li>
                                            <li className={`menu-item ${isActive('/bagOfTheMonth')}`}>
                                                <Link to="/bagOfTheMonth">
                                                    <img src={bagmonth} alt="" /> Bag of the Month
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div> <li className={`menu-item ${isActive('/discountPromoCode')}`}>
                                    <Link to="/discountPromoCode">
                                        <img src={promocode} alt="" />
                                        <span>Discount & PromoCode</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/createNewManualNotification')}`}>
                                    <Link to="/createNewManualNotification">
                                        <img src={manulnoti} alt="" />
                                        <span>Manual Notification</span>
                                    </Link>
                                </li>
                                <li className={`menu-item ${isActive('/support')}`}>
                                    <Link to="/support">
                                        <img src={Group6803} alt="" />
                                        <span>Support</span>
                                    </Link>
                                </li>
                                <li className="menu-item ncs">
                                    <img src={Group6802} alt="" />
                                    <button className='logutbtn' onClick={handleLogout}>Logout</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-md allpages allpagess">
                    <div className='pages'>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/inventoryNewBags" element={<InventoryNewBags />} />
                            <Route path="/inventorySecondLife" element={<InventorySecondLife />} />
                            <Route path="/orderManagement" element={<OrderNewBags />} />
                            <Route path="/orderViewDetail/:orderItemId" element={<OrderNewViewDetail />} /> {/* Updated route */}
                            <Route path="/secoundLifeSell" element={<SecoundLifeSell />} />
                            <Route path="/repairService" element={<RepairService />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/repairViewDetail/:orderItemId" element={<RepairViewDetail />} /> {/* Updated route */}
                            <Route path="/secoundLifeSellView/:orderId" element={<SecoundLifeSellView />} /> {/* Updated route */}
                            <Route path="/usermanagement/" element={<UserManagement/>} />
                            <Route path="/changeUserPwd/:sNo" element={<ChangeUserPwd />} /> {/* Updated route */}
                            <Route path="/createNewUser/:sNo" element={<CreateNewUser />} /> {/* Updated route */}
                            <Route path="/artistOfTheMonth" element={<ArtistOfTheMonth />} />
                            <Route path="/bagOfTheMonth" element={<BagOfTheMonth />} />
                            <Route path="/discountPromoCode" element={<DiscountPromoCode />} />
                            <Route path="/createNewArtistMonth" element={<CreateNewArtistMonth />} />   
                            <Route path="/createNewBagMonth" element={<CreateNewBagMonth/>} />   
                            <Route path="/manualNotification" element={<ManualNotification/>} />   
                            <Route path="/createNewDiscountPromoCode" element={<CreateNewDiscountPromoCode/>} />   
                            <Route path="/createNewManualNotification" element={<CreateNewManualNotification/>} />   
                            <Route path="/createNewManualNotification" element={<CreateNewManualNotification/>} />   
                            <Route path="/artoftheMonthDetail/:id" element={<ArtoftheMonthDetail/>} />   
                            <Route path="/bagofTheMonthDetail/:id" element={<BagofTheMonthDetail/>} />   
                            <Route path="/sendPromo/:id" element={<SendPromo/>} />   
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
