import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



export const CreateNewBagMonth = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [size, setSize] = useState('');
    const [images, setImages] = useState([]);
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccessMessage, setSubmitSuccessMessage] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

  
    const handleFormSubmit = async (e) => {
      e.preventDefault();
  
      // Create FormData object
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('size', size);
  
      // Append each selected image to the formData with unique names
      images.forEach((image, index) => {
        formData.append('images', image); // Use 'images' for the field name as expected by the API
      });
  
      const logFormData = (formData) => {
        const data = {};
        formData.forEach((value, key) => {
          if (key === 'images') {
            data[key] = 'Multiple files';
          } else {
            data[key] = value;
          }
        });
        console.log(data);
      };
  
      logFormData(formData);
  
      const token = localStorage.getItem('token'); // Make sure the token is saved in localStorage
  
      if (!token) {
        setSubmitError('Token not found. Please log in again.');
        return;
      }
  
      try {
        const response = await fetch('https://app.lvintage.com/admin/add/bag-of-month', {
          method: 'POST',
          headers: {
            'Authorization': token, 
          },
          body: formData, // Send FormData as bodys
        });
  
        if (response.ok) {
          setSubmitSuccessMessage('Bag added successfully!');
          setName('');
          setDescription('');
          setPrice('');
          setSize('');
          setImages([]); // Clear images array
        } else {
          const errorData = await response.json();
          console.error('Error details:', errorData);
          setSubmitError(errorData.message || 'An error occurred while submitting the form.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setSubmitError('An unexpected error occurred.');
      }
    };
    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
    const handleImageChange = (e) => {
      setImages([...e.target.files]); // Convert FileList to an array
    };
  
    return (
      <div className="container bag-form">
      <i className="fa fa-arrow-left" onClick={handleBack}></i>
        <div className="row">
          <div className="col-md"></div>
          <div className="col-md-10">
          <form onSubmit={handleFormSubmit}>
            <div className="input-container">
              <label>Bag Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Bag Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
  
            <div className="input-container">
              <label>Description</label>
              <textarea
                id="description"
                name="description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
  
            <div className="input-container">
              <label>Price</label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
  
            <div className="input-container">
              <label>Size</label>
              <input
                type="text"
                id="size"
                name="size"
                placeholder="Size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
                required
              />
            </div>
  
            <div className="input-container">
              <label>Upload Images</label>
              <input
                type="file"
                id="images"
                name="images"
                accept="image/*"
                multiple // Allow multiple image selection
                onChange={handleImageChange}
                required
              />
            </div>
  
            <button type="submit" className="submit-button">Submit Bag</button>
  
            {submitError && <p className="error-message">{submitError}</p>}
            {submitSuccessMessage && <p className="success-message">{submitSuccessMessage}</p>}
          </form>
          </div>
         <div className="col-md"></div>
        </div>
      </div>
    );
}
export default CreateNewBagMonth;