import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../repairViewDetail/RepairViewDetal.css';
import ImageZoom from '../ImageZoom/ImageZoom.js';

const RepairViewDetail = () => {
  const { orderItemId } = useParams();
  const [detailData, setDetailData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!orderItemId) {
      setError('Invalid ID');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch(`https://app.lvintage.com/admin/repairRequests`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          throw new Error('Unauthorized: Check your token');
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        if (result && Array.isArray(result.result)) {
          const foundDetail = result.result.find(item => item.id === parseInt(orderItemId));
          if (foundDetail) {
            setDetailData(foundDetail);
          } else {
            setError('No data found for the given ID');
          }
        } else {
          setError('Unexpected response format');
        }
      })
      .catch(error => {
        setError(error.message);
      });
  }, [orderItemId]);

  if (error) return <div className="error-message">{error}</div>;
  if (!detailData) return <div className="loading">Loading...</div>;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className='container rpirvie'>
      <i className="fa fa-arrow-left" onClick={handleBack} style={{ marginLeft: '-26px', marginTop: '-23px', width: '34px' }}></i>
      <div className='row'>
        <div className='col-md-6'>
          <div className="repair-view-detail">
            <h1>Repair Request Details</h1>
            <div className="details">
              <p><strong>ID:</strong> {detailData.id}</p>
              <p><strong>Email ID:</strong> {detailData.emailId}</p>
              <p><strong>Mobile Number:</strong> {detailData.mobileNumber}</p>
              <p><strong>Status:</strong> {detailData.status}</p>
              <p><strong>Estimated Cost:</strong> {detailData.estimatedCost}</p>
              {detailData.addressResponse && (
                <div className="address-details">
                  <p><strong>Address:</strong> {detailData.addressResponse.completeAddress}</p>
                  <p><strong>City:</strong> {detailData.addressResponse.city || 'N/A'}</p>
                  <p><strong>Country:</strong> {detailData.addressResponse.country || 'N/A'}</p>
                  <p><strong>Pin Code:</strong> {detailData.addressResponse.pinCode || 'N/A'}</p>
                  <p><strong>Landmark:</strong> {detailData.addressResponse.landMark || 'N/A'}</p>
                  <p><strong>Receiver Name:</strong> {detailData.addressResponse.receiverName}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          {detailData.images && detailData.images.length > 0 && (
            <div className="image-gallery">
              {detailData.images.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Repair Image ${index + 1}`} className="image" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepairViewDetail;
