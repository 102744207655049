import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useFilters } from 'react-table';
import '../../Pages/inventorySecondLife/InventorySecondLife.css';
import '../../Pages/orderNewBags/OrderNewBags.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation

export const OrderNewBags = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [categoryFilterInput, setCategoryFilterInput] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  const fetchData = () => {
    const token = localStorage.getItem('token');
    console.log('Retrieved Token:', token);

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/newBag/orders', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);

        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          let serialNumber = 1;
          const formattedData = result.result.orderResponses.flatMap(order =>
            order.orderItemResponses.map((item) => ({
              sNo: serialNumber++,
              orderId: order.orderId,
              orderItemId: item.orderItemId,
              bagId: item.bagId,
              category: item.categoryName,
              size: item.size,
              price: item.price,
              orderDetail: "View Details",
              status: order.status,
            }))
          );

          setData(formattedData);
        } else {
          console.error('Unexpected response format:', result);
          throw new Error('Unexpected response format');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  };

  useEffect(() => {
    fetchData(); // Call fetchData on component mount
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'S.No', accessor: 'sNo' },
      { Header: 'Order ID', accessor: 'orderId' },
      { Header: 'Bag ID', accessor: 'bagId' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Size', accessor: 'size' },
      { Header: 'Price', accessor: 'price' },
      {
        Header: 'Order Detail',
        accessor: 'orderDetail',
        Cell: ({ row }) => (
          <button
            className="view-detail-button"
            onClick={() => navigate(`/orderViewDetail/${row.original.orderItemId}`)}
          >
            {row.original.orderDetail}
          </button>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <StatusToggle
            initialStatus={row.original.status}
            orderId={row.original.orderId}
            onStatusChange={fetchData} // Pass fetchData to refresh the table after status change
          />
        ),
      },
    ],
    [navigate]
  );

  const tableInstance = useTable({ columns, data }, useFilters);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = tableInstance;

  const handleCategoryFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('category', value);
    setCategoryFilterInput(value);
  };

  return (
    <div>
      <div className="filter-container">
        <label htmlFor="categoryFilter">Filter by Category: </label>
        <select
          id="categoryFilter"
          value={categoryFilterInput}
          onChange={handleCategoryFilterChange}
        >
          <option value="">All</option>
          <option value="Speedy">Speedy</option>
          <option value="Neverfull">Neverfull</option>
          <option value="Keepall">Keepall</option>
        </select>
      </div>

      <table {...getTableProps()} className="modern-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

// StatusToggle component with confirmation popup
const StatusToggle = ({ initialStatus, onStatusChange, orderId }) => {
  const [status, setStatus] = useState(initialStatus);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [newStatus, setNewStatus] = useState(null); // Temporarily hold the selected status
  const [error, setError] = useState(null);

  const handleStatusChange = (status) => {
    setNewStatus(status); // Temporarily save the selected status
    setShowConfirm(true); // Show confirmation popup
  };

  const confirmStatusChange = (confirm) => {
    if (confirm) {
      setStatus(newStatus); // Change the status if confirmed
      sendStatusToAPI(newStatus); // Send the updated status to the API
    }
    setShowConfirm(false); // Close the popup
    setDropdownOpen(false); // Close the dropdown
  };

  const sendStatusToAPI = (status) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }
  
    const formData = new FormData();
    formData.append('orderId', orderId); // Corrected to send orderId instead of orderItemId
    formData.append('status', status);
  
    // Log FormData entries to the console for verification
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
  
    fetch('https://app.lvintage.com/admin/update/order/status', {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        console.log('API response:', result);
        onStatusChange(); // Refresh the table or perform any other actions
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  };
  
  

  // Function to get button background color based on status
  const getButtonColorStyle = (status) => {
    switch (status) {
      case 'APPROVED':
        return { backgroundColor: 'green', color: 'white' }; // Green background for Approved
      case 'REJECTED':
        return { backgroundColor: 'red', color: 'white' }; // Red background for Rejected
      case 'PENDING':
      default:
        return { backgroundColor: 'yellow', color: 'black' }; // Yellow background for Pending
    }
  };

  return (
    <div className="status-toggle">
      <button
        className="toggle-button"
        style={getButtonColorStyle(status)} // Apply the dynamic background color
        onClick={() => setDropdownOpen(!isDropdownOpen)}
      >
        {status} <i className="fa fa-angle-down"></i>
      </button>

      {isDropdownOpen && (
        <div className="dropdown-options">
          <button
            className="dropdown-item"
            onClick={() => handleStatusChange('APPROVED')}
          >
            Approved
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleStatusChange('REJECTED')}
          >
            Rejected
          </button>
          <button
            className="dropdown-item"
            onClick={() => handleStatusChange('PENDING')}
          >
            Pending
          </button>
        </div>
      )}

      {showConfirm && (
        <div className="confirm-popup">
          <div className="confirm-popup-content">
            <p>Are you sure you want to change the status to {newStatus}?</p>
            <button onClick={() => confirmStatusChange(true)}>Yes</button>
            <button onClick={() => confirmStatusChange(false)}>No</button>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default OrderNewBags;
