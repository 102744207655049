import React, { useEffect, useState } from 'react';
import '../../Pages/orderNewViewdetail/OrderNewViewDetail.css';
import { useParams, useNavigate } from 'react-router-dom';
import treesiximg from '../../Images/Animation - 1725956497022.gif';
import rightarow from '../../Images/right-arrow.png';
import leftarow from '../../Images/back_arrow.png';

export const OrderNewViewDetail = () => {
  const { orderItemId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const [imagePaths, setImagePaths] = useState([]); // State for storing image URLs
  const [show360View, setShow360View] = useState(true); // New state for showing the 360 view
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // For tracking the current image index
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found. Please log in again.');
      return;
    }
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch('https://app.lvintage.com/admin/newBag/orders', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.result && Array.isArray(result.result.orderResponses)) {
          const matchingOrder = result.result.orderResponses.find(order =>
            order.orderItemResponses.some(item => item.orderItemId === parseInt(orderItemId))
          );
      
          if (matchingOrder) {
            const matchingItem = matchingOrder.orderItemResponses.find(item => item.orderItemId === parseInt(orderItemId));
            setOrderDetails({
              orderId: matchingOrder.orderId,
              orderItemId: matchingItem.orderItemId,
              category: matchingItem.categoryName,
              size: matchingItem.size,
              customisationType: matchingItem.type || 'N/A', // Corrected to use 'type' for customisationType
              status: matchingOrder.status, // Accessing status from matchingOrder
              deliveryAddress: matchingOrder.addressResponse?.completeAddress || 'N/A', // Updated to get address
              cost: matchingItem.price,
              imagePath: matchingItem.itemImages || [], // Use itemImages array from API
            });
            setImagePaths(matchingItem.itemImages); // Set image paths for 360 view
          } else {
            throw new Error('Order item not found');
          }
        } else {
          throw new Error('Unexpected response format');
        }
      })      
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      });
  }, [orderItemId]);

  // Automatically cycle through images every 1 second
  useEffect(() => {
    if (show360View && imagePaths.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
      }, 1000); // Change image every 1 second

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [show360View, imagePaths]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handlePreviewClick = () => {
    setShow360View(true); // Show the 360 view when button is clicked
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagePaths.length); // Next image
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + imagePaths.length) % imagePaths.length); // Previous image
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails) {
    return <div></div>;
  }

  return (
    <div className="container-fluid orderview">
      <i className="fa fa-arrow-left" onClick={handleBack}></i> <h2 className='hding'>ORDER DETAILS</h2>
      <div className='row'>
        <div className="col-md-6">
          <div className="order-details-container">
            <h2 className="header-order-id">Order ID: {orderDetails.orderId}</h2>
            <div className="order-detail">
              <span className="label">Order Item ID:</span>
              <span className="value">{orderDetails.orderItemId}</span>
            </div>
            <div className="order-detail">
              <span className="label">Bag Category:</span>
              <span className="value">{orderDetails.category}</span>
            </div>
            <div className="order-detail">
              <span className="label">Size:</span>
              <span className="value">{orderDetails.size}</span>
            </div>
            <div className="order-detail">
              <span className="label">Customisation Type:</span>
              <span className="value">{orderDetails.customisationType}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Status:</span>
              <span className="value">{orderDetails.status}</span>
            </div>
            <div className="order-detail">
              <span className="label">Delivery Address:</span>
              <span className="value">{orderDetails.deliveryAddress}</span>
            </div>
            <div className="order-detail">
              <span className="label">Cost:</span>
              <span className="value">${orderDetails.cost}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 preview">
          {/* Render fetched images */}
          {show360View && imagePaths.length > 0 ? (
            <div className="image-gallerys">
              <img src={imagePaths[currentImageIndex]} alt={`Order Image`} className="order-image" />
            </div>
          ) : (
            <div></div>
          )}
          <div className='image-navigation'>
            <button onClick={handlePreviousImage}> <img src={leftarow} alt='' className='nexapow'></img> </button>
            <button type="button" className="preview button" onClick={handlePreviewClick}>
              <img src={treesiximg} alt="Preview button" /> <h1>Preview</h1>
            </button>
            <button onClick={handleNextImage}><img src={rightarow} alt='' className='nexapow'></img> </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderNewViewDetail;
